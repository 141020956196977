// get_ banner

export const GET_BANK = "GET_BANK";

//  create BANK
export const CREATE_BANK = "CREATE_BANK";

// update BANK
export const UPDATE_BANK = "UPDATE_BANK";

// delete BANK
export const DELETE_BANK = "DELETE_BANK";
